export function generateContractHTML(data,box_id) {
  const htmlContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Contrato - ${box_id}</title>
    <style>
      @page {
        margin: 0.2cm 0.2cm 0.2cm 0.2cm;
        padding: 0;
        size: A4;
        @bottom-center {
            content: "";
        }
      }
      body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .contrato-alto {
        max-height: 290mm!important;
        height: 290mm!important;
      }
      .container {
        max-width: 800px;
        margin: 0px auto;
        padding: 20px;
        border: 1px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
      .letterhead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      .letterhead .logo {
        width: 400px;
        height: auto;
      }
      .letterhead .contact-info {
        text-align: right;
      }
      .letterhead .contact-info p {
        margin: 0;
      }
      .letter-content {
        clear: both;
        font-size: 17px;
        text-align: justify;
      }
      .footer {
        margin-top: 20px;
        text-align: center;
        font-size: 0.8em;
        color: #666;
      }
      table {
        width: 100%;
        margin: 0 auto;
        border-collapse: collapse;
      }
      th, td {
        border: 1px;
        padding: 7px;
        width: 35%;
      }
      h2 {
        margin: 0 auto;
      }
      img {
        width: 200px!important;
      }
      ol {
        margin-top: 0px;
      }
    </style>
  </head>
  <body>
    <div class="contrato-alto">
      <div class="container">
        <div class="letterhead">
          <div class="logo">
            <img src="${data.company_logo}" alt="Logo">
            <br><span>${data.company_address}</span>
            <br><span>${data.company_phone}</span>
          </div>
          <div class="contact-info">
            <img alt="Error al generar codigo de barras" src="${data.barcode}"/>
          </div>
        </div>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th colspan="2">CONTRATO DE ENTREGA</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>Remitente</th>
                <th>Recibe</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nombre: ${data.senders_name}</td>
                <td>Nombre: ${data.receiver_name}</td>
              </tr>
              <tr>
                <td></td>
                <td>Tel: ${data.receiver_phone}</td>
              </tr>
              <tr>
                <td></td>
                <td>Dirección: ${data.receiver_address}</td>
              </tr>
              <tr>
                <td>Descripción: ${data.box_description}</td>
                <td></td>
              </tr>
              <tr>
                <td>Notas: ${data.box_notes}</td>
                <td><b>Saldo flete: $${data.saldo}</b></td>
              </tr>
              <tr>
                <td>Medida de caja: ${data.box_size}</td>
                <td></td>
              </tr>
              <tr>
                <td>Condición: ${data.box_status}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="letter-content">
          <br>
          <p>Yo: ${data.senders_name} doy fe de que la descripción de la carga anterior es la correcta y soy responsable en caso de cualquier
          alteración de dicha carga. También doy fe que en dicha caja no hay ningún tipo de carga ilegal tales como: oro, armas,
          dinero en efectivo, droga, municiones, estuches de armas, chalecos antibalas y uniformes militares. Los agentes de
          aduanas estan supuestos a inspeccionar el contenido de su caja y si se encuentra un artículo de los antes mencionados,
          el dueño de la caja estara sujeto a multas Y/O encarcelación. Además, si su caja es decomisada por aduana, no se
          garantiza la entrega a su destino final.</p>
          <b><p>La aduana exige que los aparatos electrónicos sean visibles. Para cualquier reclamo debe presentar recibo de
          compra. No garantizamos entrega puerta a puerta. Incluimos en nuestras redes sociales testimonios y fotos de
          clientes sastisfechos.</p></b>
          <b><p>
            <ol>
              <li>${data.company} NO es responsable de TV, Computadoras, celulares, tablets y
              articulos que vayan en caja con ropa y zapatos.</li>
              <li>Máximo de 6 Lociones por caja. En caso de robo o decomiso de aduana ${data.company}
              le reembolsara $500.</li>
              <li>NO Empaquete electrodomesticos con herramientas ${data.company} NO se
              hace responsable por articulos quebrados.</li>
              <li>USTED es responsable por cobros o impuestos adicionales en la aduana por cajas con
              fines de negocio.</li>
              <li>Proteja su carga con solo $50 por cada $1000 del valor total. Valor de la
              carga: _____________ Valor del Seguro: ___________________________ el seguro de carga cubre sus
              bienes por el valor total de su inversion contra todos los riesgos.</li>
            </ol>
          </p></b>
        </div>
        <div class="footer">
          <p> Firma ${data.senders_name} &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Fecha:</p>
        </div>
      </div>
    </div>
  </body>
  </html>
  `;

  const newWindow = window.open();
  newWindow.document.write(htmlContent);
  newWindow.document.close();
}


export function generateMultipleContractsHTML(dataArray, container) {
  const htmlContent = dataArray.map(data => `
    <div class="page">
      <div class="contrato-alto">
        <div class="container">
          <div class="letterhead">
            <div class="logo">
              <img src="${data.company_logo}" alt="Logo">
              <br><span>${data.company_address}</span>
              <br><span>${data.company_phone}</span>
            </div>
            <div class="barcode-info">
              <span class="container-info">${container.container_code}</span>
              <img alt="Error en el código de barras" src="${data.barcode}"/>
            </div>
          </div>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th colspan="2">CONTRATO DE ENTREGA</th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Remitente</th>
                  <th>Recibe</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nombre: ${data.senders_name}</td>
                  <td>Nombre: ${data.receiver_name}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Tel: ${data.receiver_phone}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Dirección: ${data.receiver_address}</td>
                </tr>
                <tr>
                  <td>Descripción: ${data.box_description}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Notas: ${data.box_notes}</td>
                  <td><b>Saldo flete: $${data.saldo}</b></td>
                </tr>
                <tr>
                  <td>Medida de caja: ${data.box_size}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Condición: ${data.box_status}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="letter-content">
            <br>
            <p>Yo: ${data.senders_name} doy fe de que la descripción de la carga anterior es la correcta y soy responsable en caso de cualquier
            alteración de dicha carga. También doy fe que en dicha caja no hay ningún tipo de carga ilegal tales como: oro, armas,
            dinero en efectivo, droga, municiones, estuches de armas, chalecos antibalas y uniformes militares. Los agentes de
            aduanas estan supuestos a inspeccionar el contenido de su caja y si se encuentra un artículo de los antes mencionados,
            el dueño de la caja estara sujeto a multas Y/O encarcelación. Además, si su caja es decomisada por aduana, no se
            garantiza la entrega a su destino final.</p>
            <b><p>La aduana exige que los aparatos electrónicos sean visibles. Para cualquier reclamo debe presentar recibo de
            compra. No garantizamos entrega puerta a puerta. Incluimos en nuestras redes sociales testimonios y fotos de
            clientes sastisfechos.</p></b>
            <b><p>
              <ol>
                <li>${data.company} NO es responsable de TV, Computadoras, celulares, tablets y
                articulos que vayan en caja con ropa y zapatos.</li>
                <li>Máximo de 6 Lociones por caja. En caso de robo o decomiso de aduana ${data.company}
                le reembolsara $500.</li>
                <li>NO Empaquete electrodomesticos con herramientas ${data.company} NO se
                hace responsable por articulos quebrados.</li>
                <li>USTED es responsable por cobros o impuestos adicionales en la aduana por cajas con
                fines de negocio.</li>
                <li>Proteja su carga con solo $50 por cada $1000 del valor total. Valor de la
                carga: _____________ Valor del Seguro: ___________________________ el seguro de carga cubre sus
                bienes por el valor total de su inversion contra todos los riesgos.</li>
              </ol>
            </p></b>
          </div>
          <div class="footer">
            <p> Firma ${data.senders_name} &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Fecha:</p>
          </div>
        </div>
      </div>
    </div>
  `).join('');

  const fullHtmlContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Reporte de contratos</title>
    <style>
      @page {
        margin: 0.2cm 0.2cm 0.2cm 0.2cm;
        padding: 0;
        size: A4;
        @bottom-center {
            content: "";
        }
      }
      body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .page {
        page-break-before: always;
      }
      .contrato-alto {
        height: auto;
      }
      .container-info {
        font-weight: 900;
        font-size: 22px;
        text-align: center;
        display: block;
        font-family: monospace;
      }
      .container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        border: 1px;
        border-radius: 5px;
      }
      .letterhead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      .letterhead .logo {
        width: 400px;
        height: auto;
      }
      .letterhead .barcode-info {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .letterhead .contact-info {
        text-align: right;
      }
      .letter-content {
        clear: both;
        font-size: 17px;
        text-align: justify;
      }
      .footer {
        margin-top: 20px;
        text-align: center;
        font-size: 0.8em;
        color: #666;
      }
      table {
        width: 100%;
        margin: 0 auto;
        border-collapse: collapse;
      }
      th, td {
        border: 1px;
        padding: 7px;
        width: 35%;
      }
      h2 {
        margin: 0 auto;
      }
      img {
        width: 200px!important;
      }
      ol {
        margin-top: 0px;
      }
    </style>
  </head>
  <body>
    ${htmlContent}
  </body>
  </html>
  `;

  const newWindow = window.open();
  newWindow.document.write(fullHtmlContent);
  newWindow.document.close();
}