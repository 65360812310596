//import { useState } from "react";
import useAuth from "../../auth/useAuth";
//import { ProSidebarProvider } from 'react-pro-sidebar';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { tokens } from "../../theme";
import { Box, /*IconButton,*/ Typography, useTheme } from '@mui/material'
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import CalculateIcon from '@mui/icons-material/Calculate';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
//import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
//import FactCheckIcon from '@mui/icons-material/FactCheck';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
//import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
//import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
//import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
//import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BusinessIcon from '@mui/icons-material/Business';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import UpdateIcon from '@mui/icons-material/Update';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Link, useLocation } from 'react-router-dom';


const MySidebar = ({permissionsListData}) => {
  let location = useLocation();
  const auth = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  //const [selected, setSelected] = useState(location.pathname.replace('/',''));
  //const [isCollapsed, setIsCollapsed] = useState(true);
  const {collapsed}= useProSidebar();
  //console.log('ubicacion',location.pathname)

  const permissionsAreIncluded = (vector, numeros) => {
    return numeros.some(numero => vector.includes(numero));
  };

  const handleDashboardClick = () => {
    const event = new Event('resetSearch');
    window.dispatchEvent(event);
  };


  if(auth.globals.auth && location.pathname!=='/NotFound')
  return (
      <Box
        
      >
        <Sidebar
          style={{
            height: '100%',
            minHeight:'100vh',
            top: 'auto',
          }}

          breakPoint="md"
          backgroundColor={colors.primary[400]}
          
        >
          {!collapsed?<Box textAlign="center">
            <Typography 
              variant="h3" 
              color={colors.grey[100]} 
              fontWeight="bold" 
              sx={{ m:"40px 0 0 0"}}
            >
              {auth.globals.user}
            </Typography>
            <Typography
              variant="h5" 
              color={colors.greenAccent[500]}
              sx={{ m:"0 0 40px 0"}} 
            >
              {auth.globals.email}
            </Typography>  
            <Typography
              variant="h4"
              fontWeight="bold"
              color={colors.greenAccent[500]}
              sx={{ m:"0 0 40px 0"}} 
            >
              {auth.globals.name_role}
            </Typography>            

          </Box>:null}
          <Menu 
          menuItemStyles={{
            button: ({ level, active, disabled}) => {
              // only apply styles on first level elements of the tree
              return {
                color: active ? "#2c75b6 !important": colors.grey[100],
                backgroundColor: !active ?`${colors.primary[400]}`:`${colors.primary[500]}`,
                '&:hover': {
                  backgroundColor: `${colors.primary[500]}`,
                },
              };
              
            },
          }}>
            <MenuItem onClick={handleDashboardClick} active={location.pathname==='/Dashboard'} component={<Link to="/Dashboard"/> } icon={<AppRegistrationOutlinedIcon/>}>
              <Typography>
                  Aplicaciones
              </Typography>
            </MenuItem>
            <MenuItem active={location.pathname==='/Cuenta'} component={<Link to="/Cuenta"/>} icon={<AccountBoxIcon/>}>
              <Typography>
                  Cuenta
            </Typography>
            </MenuItem>
            {permissionsAreIncluded(permissionsListData,[15,17,19,22,27])&&
            <SubMenu label="Admin" icon={<AdminPanelSettingsIcon/>}>
              {permissionsListData.includes(22) && 
              <MenuItem active={location.pathname.includes('/Users')} component={<Link to="/Users"/>} icon={<GroupIcon/>}>
                <Typography>
                    Usuarios
                </Typography>
              </MenuItem>}
              {permissionsListData.includes(15)&&<MenuItem active={location.pathname==='/RutasOrigen/DetalleAsignacion'} component={<Link to="/RutasOrigen/DetalleAsignacion"/>} icon={<AltRouteIcon/>}>
                <Typography>
                    Rutas
                </Typography>
              </MenuItem>}

              {permissionsListData.includes(17)&&<MenuItem active={location.pathname==='/CalendarioCierre'} component={<Link to="/CalendarioCierre"/>} icon={<CalendarMonthIcon/>}>
                <Typography>
                    Calendario de cierre
                </Typography>
              </MenuItem>}

              {permissionsListData.includes(17)&&<MenuItem active={location.pathname==='/Cuadre'} component={<Link to="/Cuadre"/>} icon={<CalculateIcon/>}>
                <Typography>
                    Cuadre
                </Typography>
              </MenuItem>}
              {permissionsListData.includes(19)&&<MenuItem active={location.pathname==='/Contenedores'} component={<Link to="/Contenedores"/>} icon={<DirectionsBoatIcon/>}>
                <Typography>
                    Containers
                </Typography>
              </MenuItem>}
              {/*<MenuItem active={location.pathname==='/Rutas'} component={<Link to="/Rutas"/>} icon={<FactCheckIcon/>}>
                <Typography>
                    Bitácora
                </Typography>
              </MenuItem>*/}
              {permissionsListData.includes(27)&&<MenuItem active={location.pathname==='/VerPagos'} component={<Link to="/VerPagos"/>} icon={<RequestQuoteOutlinedIcon/>}>
                <Typography>
                    Ver pagos
                </Typography>
              </MenuItem>}
              {/*<MenuItem active={location.pathname==='/Rutas'} component={<Link to="/Rutas"/>} icon={<PublishedWithChangesOutlinedIcon/>}>
                <Typography>
                    Cambiar estados de cajas
                </Typography>
              </MenuItem>*/}
              {permissionsListData.includes(32)&&<MenuItem active={location.pathname==='/SMS'} component={<Link to='/SMS'/>} icon={<SmsOutlinedIcon/>}>
                <Typography>
                    SMS de ruta
                </Typography>
              </MenuItem>}
              {permissionsListData.includes(33)&&<MenuItem active={location.pathname==='/CajasPorFecha'} component={<Link to='/CajasPorFecha'/>} icon={<DateRangeIcon/>}>
                <Typography>
                    Próximas Rutas
                </Typography>
              </MenuItem>}
              {permissionsListData.includes(42)&&<MenuItem active={location.pathname==='/CajasConSaldo'} component={<Link to="/CajasConSaldo"/>} icon={<TrendingDownIcon/>}>
                <Typography>
                    Saldo Pendiente
                </Typography>
              </MenuItem>}
              {permissionsListData.includes(44)&&<MenuItem 
                  active={location.pathname==='/Tracking'} 
                  component="a"
                  href="/Tracking"
                  target="_blank"
                  icon={<UpdateIcon/>}>
                <Typography>
                    Tracking
                </Typography>
              </MenuItem>}
              {permissionsListData.includes(46)&&<MenuItem active={location.pathname==='/Audit'} component={<Link to="/Audit"/>} icon={<VerifiedUserIcon/>}>
                <Typography>
                    Audit
                </Typography>
              </MenuItem>}
            </SubMenu>}
            {permissionsAreIncluded(permissionsListData,[23,24,25,26])&&
            <SubMenu label="Catálogos" icon={<SettingsOutlinedIcon/>}>
              {permissionsListData.includes(23)&&<MenuItem active={location.pathname==='/Companies'} component={<Link to="/Companies"/>} icon={<BusinessIcon/>}>
                <Typography>
                    Empresas
                </Typography>
              </MenuItem>}
              {permissionsListData.includes(24)&&<MenuItem active={location.pathname==='/Stores'} component={<Link to="/Stores"/>} icon={<StoreOutlinedIcon/>}>
                <Typography>
                    Bodegas
                </Typography>
              </MenuItem>}
              {permissionsListData.includes(25)&&<MenuItem active={location.pathname.includes('/Roles')} component={<Link to="/Roles"/>} icon={<SupervisorAccountIcon/>}>
                <Typography>
                    Roles
                </Typography>
              </MenuItem>}           
              {permissionsListData.includes(26)&&<MenuItem active={location.pathname.includes('/Sizes')} component={<Link to="/Sizes"/>} icon={<StraightenOutlinedIcon/>}>
                <Typography>
                    Tamaños
                </Typography>
              </MenuItem>}
              {/*<MenuItem active={location.pathname==='/Rutas'} component={<Link to="/Rutas"/>} icon={<InventoryOutlinedIcon/>}>
                <Typography>
                    Inventario de cajas
                </Typography>
              </MenuItem>*/}
              {/*<MenuItem active={location.pathname==='/Rutas'} component={<Link to="/Rutas"/>} icon={<Inventory2OutlinedIcon/>}>
                <Typography>
                    Asignar Cajas
                </Typography>
              </MenuItem>*/}
            </SubMenu>}
          </Menu>
        </Sidebar>
      </Box>  );
}

export default MySidebar;