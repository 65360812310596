//import { Box/*,useTheme*/ } from "@mui/material";
import React, { useState } from 'react';
import { Box, TextField, Button, Grid, Paper, Typography, Dialog, DialogContent, FormControl, /*FormLabel,*/ RadioGroup, FormControlLabel, Radio, InputLabel, Select, MenuItem,DialogTitle,DialogActions  } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import { tokens } from "../../../../theme";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { getContainerByCodeRoute, getContainerByIdRoute, /*getContainerDetailsRoute,*/ getContainerStatusRoute, modifyContainerRoute, newContainerRoute, reseiverCountriesRoute, storesListRoute } from "../../../../config";
import { useTheme } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import ContainerDetail from './ContainerDetail';

const Carga = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  //const {data,loading,error} = useFetch2(`${url}/api/clients`,token)

  const navigate = useNavigate();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [errorRequest, setErrorRequest] = useState(null);

  const { data: countriesListData, loading: countriesListLoading, error: countriesListError } = useFetchGET(reseiverCountriesRoute(),token,[]);
  //console.log('paises',countriesListData)
  const { data: statusListData, loading: statusListLoading, error: statusListError } = useFetchGET(getContainerStatusRoute(),token,[]);
  //console.log('estados',statusListData)
  const { data: storesListData, loading: storesListLoading, error: storesListError } = useFetchGET(storesListRoute(),token,[]);
  //console.log('stores',storesListData)

  const [searchType, setSearchType] = useState('id');

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  //const [containerRecord, setContainerRecord] = useState(null);
  //const [isEditMode, setIsEditMode] = useState(false);

  // Esquema de validación
  const containerSchema = yup.object().shape({
    container_code: yup.string(), // Asegúrate de que este nombre coincida con el nombre del campo en el formulario
    container_date: yup.date().required('* Fecha requerida').nullable(),
    origin_store: yup.string().required('* Origen requerido'), // Aquí también verifica que coincida con el nombre del campo
    destination_country: yup.string().required('* Destino requerido'), // Y aquí
    booking: yup.string(), // Verifica el nombre
    sello: yup.string(), // Verifica el nombre
    // Agrega cualquier otro campo que necesites validar
});

  /*const handleExport = () => {
      // Lógica para exportar datos del contenedor
  };*/


  const getContainer = async(code) =>{
    if(code && code !== "" ){
      try{
        setLoadingRequest(true)
        //console.log('id_container',code)
        let res = await fetch(searchType==='id'?getContainerByIdRoute(code):getContainerByCodeRoute(code), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('container',data)
        if(res.ok){
          setRecord(data[0])
          if(data.length===0){
            mostrarErrorRequest("Contenedor no encontrado")
          }
        }else{
          //console.log('res',res)
          if(res.status === 404){
            mostrarErrorRequest("Registro no encontrado")
            setRecord(null)
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const [record, setRecord] = useState(null);
  
  const searchFormik = useFormik({
    initialValues: {
        recordId: '',
    },
    onSubmit: (values) => {
      //console.log(values)
      getContainer(values.recordId)
    },
  });


  const newContainer = async(body) =>{
    //console.log('bpdy',body)
    try{
      
      let res = await fetch(newContainerRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados nuevo contenedor ',data)
      if(res.ok){
        alertSucess("Contenedor ID: "+data.contenedor_id +" creado con éxito",data.message)
        navigate("/Carga")
        setSearchType('id')
        searchFormik.values.recordId = data.contenedor_id 
        getContainer(data.contenedor_id)          
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)
        //console.log('Error')     
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      //console.log('Error catch') 
    }
  }


  const modifyContainer = async(body) =>{
    //console.log('bpdy',body)
    try{      
      let res = await fetch(modifyContainerRoute(body.id), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados nuevo contenedor ',data)
      if(res.ok){
        alertSucess("Contenedor modificado con éxito",data.message)
        navigate("/Carga")
        setSearchType('id')
        searchFormik.values.recordId = body.id 
        getContainer(body.id)          
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)
        //console.log('Error')     
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      //console.log('Error catch') 
    }
  }



  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  /*const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }*/

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    //navigate("/Dashboard")
  }

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState('new'); // 'new' o 'edit'

  const openDialog = (mode, containerData) => {
    setDialogMode(mode);
    if (mode === 'edit') {
      formik.setValues(
        {
          id: containerData.id,
          container_code: containerData.container_code,
          container_date: containerData.container_date,
          origin_store: containerData.origin_store.toString(),
          destination_country: containerData.destination_country.toString(),
          booking: containerData.booking,
          sello: containerData.sello,
        }
      );
    } else {
      formik.resetForm();
    }
    setIsDialogOpen(true);
  };
  
  const closeDialog = () => {
    setIsDialogOpen(false);
    formik.resetForm();
  };


  const formik = useFormik({
    initialValues: {
      id: '',
      container_code: '',
      container_date: '',
      origin_store: '',
      destination_country: '',
      booking: '',
      sello: '',
      status: '5',
    },
    validationSchema: containerSchema, // Asegúrate de definir esto
    onSubmit: (values) => {
      values.origin_store = values.origin_store.toString()
      values.destination_country = values.destination_country.toString()
      if (dialogMode === 'edit') {
        //console.log('editar',values)
        modifyContainer(values)
      } else {
        //console.log('nuevo',values)
        newContainer(values)
      }
      closeDialog();
    },
  });


  if (loadingRequest || countriesListLoading|| statusListLoading|| storesListLoading) return <Loading />;
  if (errorRequest|| countriesListError|| statusListError||storesListError) return <FatalError />;

  return (
    <Box m="15px" marginBottom="64px">
      <Header title="Gestión de Contenedores" subtitle="Buscar Contenedor" />

      <form onSubmit={searchFormik.handleSubmit}>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'left', 
            alignItems: 'center', 
            gap: 2,
            padding: 1,
          }}
        >
          <FormControl component="fieldset">
            <Typography>Buscar por:</Typography>
            <RadioGroup
              row
              name="searchType"
              value={searchType}
              onChange={handleSearchTypeChange}
            >
              <FormControlLabel 
                value="id"
                label="ID" 
                sx={{ color: colors.primary[100] }}
                control={
                  <Radio 
                    sx={{ color: colors.primary[100],
                    '&.Mui-checked': {
                      color: colors.primary[100],
                    }
                    }} 
                  />}
              />
              <FormControlLabel 
                value="container_code" 
                label="Código de Contenedor" 
                sx={{ color: colors.primary[100] }}
                control={
                  <Radio 
                    sx={{ color: colors.primary[100],
                    '&.Mui-checked': {
                      color: colors.primary[100],
                    }
                    }} 
                  />}
              />
            </RadioGroup>
          </FormControl>
          <TextField
            variant="filled"
            label= {searchType==='id'?'ID':'Código'}
            name="recordId"
            value={searchFormik.values.recordId}
            onChange={searchFormik.handleChange}
            size="small"
          />
          <Button type="submit" variant="contained" color="secondary">
            <SearchIcon/>
            Buscar
          </Button>
          <Button variant="contained" color="secondary" onClick={() => openDialog('new')}><AddIcon/>Nuevo Contenedor</Button>
        </Box>
      </form>      
      
      {record && (
        <Paper style={{ padding: '15px' }}>
          <form>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  label="Id"
                  name="id"
                  value={record.id}
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  label="Código Contenedor"
                  name="container_code"
                  value={record.container_code}
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  label="Fecha"
                  name="container_date"
                  type="date"
                  value={record.container_date}
                  fullWidth
                  variant="filled"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="filled"
                  disabled={true}
                  fullWidth
                  size="small"
                >
                  <InputLabel id="select-store-label" name="select-store-label">Origen</InputLabel>
                  <Select
                    name = "origin_store"
                    value = {record.origin_store}
                  >
                    {storesListData.map((store) => (
                      <MenuItem key={store.id} value={store.id.toString()}>
                        {store.name}
                      </MenuItem>
                    ))}
                  </Select>  
                </FormControl> 
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="filled"
                  disabled={true}
                  fullWidth
                  size="small"
                >
                <InputLabel>Destino</InputLabel>
                  <Select
                    name="destination_country"
                    value={record.destination_country}
                  >
                    {countriesListData.map((country) => (
                      <MenuItem key={country.id} value={country.id.toString()}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  label="Booking"
                  name="booking"
                  value={record.booking}
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={true}
                  label="Sello"
                  name="sello"
                  value={record.sello}
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="filled"
                  disabled={true}
                  fullWidth
                  size="small"
                >
                  <InputLabel id="status-label" name="status-label">Estado Contenedor</InputLabel>
                  <Select
                    name = "status"
                    value = {record.status.toString()}
                  >
                    {statusListData.map((status) => (
                      <MenuItem key={status.id} value={status.id.toString()}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>  
                </FormControl> 
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="secondary" onClick={() => openDialog('edit', record)}><SaveIcon/>Editar Contenedor</Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
      {
        <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxWidth="sm">
          <DialogTitle>{dialogMode === 'new' ? 'Nuevo Contenedor' : 'Editar Contenedor'}</DialogTitle>
          <DialogContent>
            <form onSubmit={formik.handleSubmit}>
              {/* Código Contenedor */}
              <TextField
                label="Código Contenedor"
                name="container_code"
                value={formik.values.container_code}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
                onBlur={formik.handleBlur}
                error={formik.touched.container_code && Boolean(formik.errors.container_code)} 
                helperText={formik.touched.container_code && formik.errors.container_code}
              />

              {/* Fecha */}
              <TextField
                label="Fecha"
                name="container_date"
                type="date"
                value={formik.values.container_date}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                onBlur={formik.handleBlur}
                error={formik.touched.container_date && Boolean(formik.errors.container_date)} 
                helperText={formik.touched.container_date && formik.errors.container_date}
              />

              {/* Tienda de Origen */}
              <FormControl fullWidth margin="normal" required>
                <InputLabel id="origin-store-label">Tienda de Origen</InputLabel>
                <Select
                  labelId="origin-store-label"
                  name="origin_store"
                  value={formik.values.origin_store.toString()}
                  label="Tienda de Origen"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {storesListData.map((store) => (
                    <MenuItem key={store.id} value={store.id.toString()}>
                      {store.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* País de Destino */}
              <FormControl fullWidth margin="normal" required>
                <InputLabel id="destination-country-label">País de Destino</InputLabel>
                <Select
                  labelId="destination-country-label"
                  name="destination_country"
                  value={formik.values.destination_country.toString()}
                  label="País de Destino"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {countriesListData.map((country) => (
                    <MenuItem key={country.id} value={country.id.toString()}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Booking */}
              <TextField
                label="Booking"
                name="booking"
                value={formik.values.booking}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
                onBlur={formik.handleBlur}
                error={formik.touched.booking && Boolean(formik.errors.booking)} 
                helperText={formik.touched.booking && formik.errors.booking}
              />

              {/* Sello */}
              <TextField
                label="Sello"
                name="sello"
                value={formik.values.sello}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
                onBlur={formik.handleBlur}
                error={formik.touched.sello && Boolean(formik.errors.sello)} 
                helperText={formik.touched.sello && formik.errors.sello}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="error">Cancelar</Button>
            <Button onClick={formik.handleSubmit} color="secondary">
              {dialogMode === 'new' ? 'Crear' : 'Guardar Cambios'}
            </Button>
          </DialogActions>
        </Dialog>        
      }
    {record && <ContainerDetail id_container={record.id} container={record}/>}
    </Box>
  );
}

export default Carga;