import React, { useState, useCallback } from "react";
import { Snackbar, Alert } from "@mui/material";


export const useCustomSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState(null);

  const closeSnackbar = useCallback(() => setOpen(false), []);

  const showSnackbar = useCallback(
    (message, severity, duration) => {
      setSnackbarOptions({ message, severity, duration });
      setOpen(true);
    }, []);

  const showSuccessSnackbar = useCallback(
    (message, duration = 2500) => showSnackbar(message, "success", duration),
    [showSnackbar]
  );

  const showErrorSnackbar = useCallback(
    (message, duration = 2500) => showSnackbar(message, "error", duration),
    [showSnackbar]
  );

  const showWarningSnackbar = useCallback(
    (message, duration = 2500) => showSnackbar(message, "warning", duration),
    [showSnackbar]
  );

  const showInfoSnackbar = useCallback(
    (message, duration = 2500) => showSnackbar(message, "info", duration),
    [showSnackbar]
  );

  const CustomSnackbarComponent = (
    <Snackbar
      open={open}
      autoHideDuration={snackbarOptions?.duration || 2500}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}>
      <Alert
        severity={snackbarOptions?.severity}
        variant="filled"
        onClose={closeSnackbar}>
        {snackbarOptions?.message}
      </Alert>
    </Snackbar>
  );

  return {
    showSuccessSnackbar,
    showErrorSnackbar,
    showWarningSnackbar,
    showInfoSnackbar,
    CustomSnackbarComponent,
  };

};
