import { Box, MenuItem, TextField, Button, InputLabel, FormControl, Typography, Table, TableCell, Paper, TableRow, TableBody, TableContainer, TableHead } from "@mui/material";
import Header from "../../../../components/Header";
import useAuth from "../../../../auth/useAuth";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from '@mui/material/Select';
import swal from "sweetalert";
import { useEffect, useState } from "react";
import FatalError from "../../../global/FatalError";
import Loading from "../../../global/Loading";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { usersByRolRoute, getClosureDelivery, getClosurePickup, getExpensesDateDriver, getCashBalanceByUser } from "../../../../config";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import CierreRutaCashSection from "./CierreRutaCashSection";

const CierreRuta = ({permissionsListData}) => {
  const auth = useAuth();
  const token = auth.globals.token;
  const [searchParams] = useSearchParams();
  const prevDate = searchParams.get("date");
  const prevDriverId = searchParams.get("driver");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [errorRequest, setErrorRequest] = useState(null);
  const [record, setRecord] = useState([]);
  const [recordPickup, setRecordPickup] = useState([]);
  const [recordExpenses, setRecordExpenses] = useState([]);
  const [recordCash, setRecordCash] = useState([]);
  const [totalDeliveryCash, setTotalDeliveryCash] = useState(0);
  const [totalPickupCash, setTotalPickupCash] = useState(0);
  const [totalDeliveryTransfer, setTotalDeliveryTransfer] = useState(0);
  const [totalPickupTransfer, setTotalPickupTransfer] = useState(0);
  const [totalDeliveryCheck, setTotalDeliveryCheck] = useState(0);
  const [totalPickupCheck, setTotalPickupCheck] = useState(0);
  const [totalDeliveryPayed, setTotalDeliveryPayed] = useState(0);
  const [totalPickupPayed, setTotalPickupPayed] = useState(0);
  const [totalDeliveryCost, setTotalDeliveryCost] = useState(0);
  const [totalPickupCost, setTotalPickupCost] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const total = isNaN((totalDeliveryCash + totalPickupCash) - totalExpenses) ? 0 : (totalDeliveryCash + totalPickupCash) - totalExpenses - totalCash;

  const { data: usersListData, loading: usersListLoading, error: usersListError } = useFetchGET(usersByRolRoute(2), token, []);

  const getDetailClosureDelivery = async (date, user_driver) => {
    if (date && date !== "") {
      try {
        setLoadingRequest(true)
        let res = await fetch(getClosureDelivery(date, user_driver), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
         // cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('delivery',data)
        if (res.ok) {
          setTotalDeliveryCash(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.cash)) ? 0 : parseFloat(item.cash)), 0));
          setTotalDeliveryTransfer(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.transfer)) ? 0 : parseFloat(item.transfer)), 0));
          setTotalDeliveryCheck(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.cheque)) ? 0 : parseFloat(item.cheque)), 0));
          setTotalDeliveryPayed(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.payed)) ? 0 : parseFloat(item.payed)), 0));
          setTotalDeliveryCost(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.delivery_cost)) ? 0 : parseFloat(item.delivery_cost)), 0));
          setRecord(Array.isArray(data) ? data : [])
        } else {
          if (res.status === 404) {
            showErrorAlert("Registro no encontrado")
            setRecord(null)
          }
          if (res.status === 401 || res.status === 403) {
            showErrorAlert("No tiene autorización o su sesión ha expirado")
          }
        }
        setLoadingRequest(false)
        return data;
      } catch (errorRequest) {
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const getDetailClosurePickup = async (date, user_driver) => {
    if (date && date !== "") {
      try {
        setLoadingRequest(true)
        let res = await fetch(getClosurePickup(date, user_driver), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
         // cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('pickup',data)
        if (res.ok) {
          setTotalPickupCash(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.cash)) ? 0 : parseFloat(item.cash)), 0));
          setTotalPickupTransfer(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.transfer)) ? 0 : parseFloat(item.transfer)), 0));
          setTotalPickupCheck(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.cheque)) ? 0 : parseFloat(item.cheque)), 0));
          setTotalPickupPayed(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.payed)) ? 0 : parseFloat(item.payed)), 0));
          setTotalPickupCost(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.delivery_cost)) ? 0 : parseFloat(item.delivery_cost)), 0));
          setRecordPickup(Array.isArray(data) ? data : [])
        } else {
          if (res.status === 404) {
            showErrorAlert("Registro no encontrado")
            setRecord(null)
          }
          if (res.status === 401 || res.status === 403) {
            showErrorAlert("No tiene autorización o su sesión ha expirado")
          }
        }
        setLoadingRequest(false)
        return data;
      } catch (errorRequest) {
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const getDetailExpenses = async (date, user_driver) => {
    if (date && date !== "") {
      try {
        setLoadingRequest(true)
        let res = await fetch(getExpensesDateDriver(date, user_driver), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        if (res.ok) {
          setTotalExpenses(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.amount)) ? 0 : parseFloat(item.amount)), 0));
          setRecordExpenses(Array.isArray(data) ? data : [])
        } else {
          if (res.status === 404) {
            showErrorAlert("Registro no encontrado")
            setRecord(null)
          }
          if (res.status === 401 || res.status === 403) {
            showErrorAlert("No tiene autorización o su sesión ha expirado")
          }
        }
        setLoadingRequest(false)
        return data;
      } catch (errorRequest) {
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const getDetailCashEntries = async (date, user_driver) => {
    if (! date) {
      console.warn('Date is required for getting cash entries');
      return;
    }
    try {
      setLoadingRequest(true);
      const response = await getCashBalanceByUser(token, user_driver, date);
      setLoadingRequest(false);
      if (response.ok) {
        const data = await response.json();
        setTotalCash(data.reduce((acc, item) => acc + (isNaN(parseFloat(item.amount)) ? 0 : parseFloat(item.amount)), 0));
        setRecordCash(Array.isArray(data) ? data : []);
        return data;
      }
      if (response.status === 404) {
        showErrorAlert("Registros no encontrados");
        setRecord(null);
      }
      if (response.status === 401 || response.status === 403) {
        showErrorAlert("No tiene autorización o su sesión ha expirado");
      }
    } catch (errorRequest) {
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const performDataSearch = (values) => {
    console.log('SEARCH VALUES', values);
    try {
      setLoadingRequest(true);
      setShowInformation(true);
      getDetailClosureDelivery(values.date, values.user_driver);
      getDetailClosurePickup(values.date, values.user_driver);
      getDetailExpenses(values.date, values.user_driver);
      getDetailCashEntries(values.date, values.user_driver);
    } catch (error) {
      //console.log(error);
    }
  }

  const updateOnlyCashEntries = () => {
    try {
      getDetailCashEntries(searchFormik.values.date, searchFormik.values.user_driver);
    } catch (error) {
      console.error('Error updating cash-entries');
    }
  }


  const searchFormik = useFormik({
    initialValues: {
      date: prevDate || '',
      user_driver: prevDriverId || ''
    },
    onSubmit: performDataSearch
  });

  useEffect(() => {
    if (prevDate && prevDriverId) {
      performDataSearch({date:prevDate, user_driver:prevDriverId});
    }
  }, [prevDate, prevDriverId])


  // ---------------------------------------------------------- ALERTS ----------------------------------------------------------------------------
  const showErrorAlert = (error) => {
    if (error != null) {
      try {
        swal({
          title: "Error",
          text: "Resultado: " + error,
          icon: "error",
          button: "Aceptar"
        })
        if (error === "No tiene autorización o su sesión ha expirado") {
          auth.logout();
        }
      } catch (e) {
        swal({
          title: "Error",
          text: "Error inesperado ",
          icon: "error",
          button: "Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  if (usersListLoading || loadingRequest)
    return (<Loading />)

  if (usersListError || errorRequest)
    return (<FatalError />)

  return (
    <Box m="20px" marginBottom="64px">
      <Header title="Cierre de ruta" subtitle="Cierre de ruta"  />

      { showInformation && (<Header title={`Líquido a recibir: ${total}`}  /> )}

      <form onSubmit={searchFormik.handleSubmit}>
        <Box display="grid"
          gap="15px"
          marginBottom="15px"
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 12" }
          }} >

          <TextField
            disabled={false}
            fullWidth={!isNonMobile}
            variant="filled"
            type="date"
            label="Fecha"
            asp-format="{0:yyyy-MM-dd}"
            name="date"
            value={searchFormik.values.date}
            onChange={searchFormik.handleChange}
            size="small"
            sx={{ gridColumn: "span 3" }}
            required
          />

          <FormControl variant="filled"
            disabled={false}
            sx={{ gridColumn: "span 5" }}
            required>
            <InputLabel id="select-driver-label" name="select-driver-label">Driver asignado</InputLabel>
            <Select
              labelId="select-driver-label"
              id="select-driver"
              fullWidth={!isNonMobile}
              name="user_driver"
              size="small"
              value={searchFormik.values.user_driver}
              onChange={searchFormik.handleChange}
            >

              {usersListData.map((user) => (
                (permissionsListData.includes(41)?
                  <MenuItem key={user.id} value={user.id.toString()}>
                    {user.name + ' ' + user.lastname}
                  </MenuItem>
                  :user.id === auth.globals.id?
                  <MenuItem key={user.id} value={user.id.toString()}>
                    {user.name + ' ' + user.lastname}
                  </MenuItem>
                :undefined)
              ))}
            </Select>
          </FormControl>

          <Button type="submit" variant="contained" color="secondary">
            Mostrar
          </Button>
        </Box>
      </form>

      { showInformation && permissionsListData.includes(17) &&
         <CierreRutaCashSection
          recordCash={recordCash}
          totalCash={totalCash}
          maxTotalBalance={total}
          requireUpdateUI={updateOnlyCashEntries}
          selectedDate={searchFormik.values.date}
          selectedDriver={searchFormik.values.user_driver}>
        </CierreRutaCashSection> }

      { showInformation && (
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold'  }}> Ingresos </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>ID</TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Empresa</TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Nombre del cliente&nbsp; </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Teléfono del cliente&nbsp; </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>País&nbsp; </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Ruta&nbsp; </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Cobrado por el driver (efectivo) &nbsp; </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Transferido&nbsp; </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Cheque&nbsp; </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Pagado&nbsp; </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}> Monto a cobrar &nbsp; </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={11} style={{ fontWeight: 'bold' }}> Delivery </TableCell>
              </TableRow>
              {
                record && record.map((item) =>
                  <TableRow key={item.id}>
                    <TableCell align="right">{item.correlative}</TableCell>
                    <TableCell align="right">{item.company}</TableCell>
                    <TableCell align="right">{item.senders_name}</TableCell>
                    <TableCell align="right">{item.senders_phone}</TableCell>
                    <TableCell align="right">{item.name}</TableCell>
                    <TableCell align="right">{item.route_name}</TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>{item.cash}</TableCell>
                    <TableCell align="right">{item.transfer}</TableCell>
                    <TableCell align="right">{item.cheque}</TableCell>
                    <TableCell align="right">{item.payed}</TableCell>
                    <TableCell align="right">{item.delivery_cost}</TableCell>
                  </TableRow>
                )
              }
              <TableRow>
                <TableCell colSpan={5}/>
                <TableCell align="right" style={{ fontWeight: 'bold' }}> Total </TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold' }}> {totalDeliveryCash} </TableCell>
                <TableCell align="right"> {totalDeliveryTransfer} </TableCell>
                <TableCell align="right"> {totalDeliveryCheck} </TableCell>
                <TableCell align="right"> {totalDeliveryPayed} </TableCell>
                <TableCell align="right"> {totalDeliveryCost} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={11} style={{ fontWeight: 'bold' }}> Pickup </TableCell>
              </TableRow>
              {
                recordPickup && recordPickup.map((item) =>
                  <TableRow key={item.id}>
                    <TableCell align="right">{item.correlative}</TableCell>
                    <TableCell align="right">{item.company}</TableCell>
                    <TableCell align="right">{item.senders_name}</TableCell>
                    <TableCell align="right">{item.senders_phone}</TableCell>
                    <TableCell align="right">{item.name}</TableCell>
                    <TableCell align="right">{item.route_name}</TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>{item.cash}</TableCell>
                    <TableCell align="right">{item.transfer}</TableCell>
                    <TableCell align="right">{item.cheque}</TableCell>
                    <TableCell align="right">{item.payed}</TableCell>
                    <TableCell align="right">{item.delivery_cost}</TableCell>
                  </TableRow>
                )
              }
              <TableRow>
                <TableCell colSpan={5}/>
                <TableCell align="right" style={{ fontWeight: 'bold' }}> Total </TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold' }}> {totalPickupCash} </TableCell>
                <TableCell align="right"> {totalPickupTransfer} </TableCell>
                <TableCell align="right"> {totalPickupCheck} </TableCell>
                <TableCell align="right"> {totalPickupPayed} </TableCell>
                <TableCell align="right"> {totalPickupCost} </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      )}

      { showInformation && (
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold'  }}> Gastos </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Categoría de gasto </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}> Tipo de gasto &nbsp; </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}> Monto &nbsp; </TableCell>
                <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}> Descripción &nbsp; </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            { recordExpenses && recordExpenses.map((item) =>
              <TableRow key={item.id}>
                <TableCell align="right">{item.category}</TableCell>
                <TableCell align="right">{item.fee_type}</TableCell>
                <TableCell align="right">{item.amount}</TableCell>
                <TableCell align="right">{item.fee_description}</TableCell>
              </TableRow> )}

              <TableRow>
                <TableCell />
                <TableCell align="right" style={{ fontWeight: 'bold' }}> Total </TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold' }}> {totalExpenses} </TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box> )}

    </Box>
  );
}

export default CierreRuta;