import { CssBaseline, ThemeProvider } from "@mui/material";
import React, { useEffect } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from './auth/AuthProvider';
import GoogleMapsScriptLoader from './GoogleMapsScriptLoader';
import AppRouter from "./routers/AppRouter";
import { ColorModeContext, useMode } from "./theme";
import { ENVIRONMENT_DEVELOP, ENVIRONMENT_PRODUCTION } from "./utils/Constants";
import packageJson from "../package.json";


function App() {

  const environment = process.env.REACT_APP_ENVIRONMENT || ENVIRONMENT_DEVELOP;
  const buildVersion = process.env.AWS_APP_ID || 'dev';
  const estadoIncial = localStorage.getItem('themeMode') || 'light';
  const [theme, colorMode] = useMode(estadoIncial);

  useEffect(() => {
    console.log(`Current in '${environment}' environment...`);
  }, []);

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <div translate="no">
          <ProSidebarProvider>
            <CssBaseline/>
            <Router>
              <GoogleMapsScriptLoader>
                <AppRouter/>
              </GoogleMapsScriptLoader>
            </Router>
          </ProSidebarProvider>  
          </div>
          { environment !== ENVIRONMENT_PRODUCTION && <div className='test-indicator'>
            <span>USTED ESTÁ EN EL AMBIENTE DE PRUEBAS - v{packageJson.version} build:{buildVersion}</span>
          </div> }
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}

export default App;
