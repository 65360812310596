import React, { useState } from 'react';
import { Box, Button, IconButton, Typography, /*TextField, Typography,useMediaQuery,*/ useTheme } from "@mui/material";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { DataGrid, esES } from "@mui/x-data-grid";
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
//import { tokens } from "../../theme";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { format } from 'date-fns';
import swal from "sweetalert";
import useAuth from "../../auth/useAuth";
import AppsCards from "../../components/AppsCards";
import SearchComponent from "../../components/SearchComponent";
import { searchRoute } from "../../config";
import { tokens } from "../../theme";
import FatalError from "../global/FatalError";
import Loading from "../global/Loading";
import InactivateDialog from "./Apps/Delivery/InactivateDialog";
import ChangeBoxStatusModal from "../../components/ChangeBoxStatusModal";
import { PERMISSION_CHANGE_BOX_STATUS } from '../../utils/Constants';

const Dashboard = ({ permissionsListData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  //const isNonMobile = useMediaQuery("(min-width:600px)")
  const navigate = useNavigate();
  const auth = useAuth();
  const token = auth.globals.token

  const [searchResult, setSearchResult] = useState("");
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [errorRequest, setErrorRequest] = useState(null)
  const [openInactivateDialog, setOpenInactivateDialog] = useState(false);
  const [lastSearchParams, setLastSearchParams] = useState(null);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const [selectedBox, setSelectedBox] = useState();
  const [lastSearch, setLastSearch] = useState();
  /*const {data,loading,error} = useFetch2(`${url}/api/pools`,token)
 */

  const formatDateTime = (value) => {
    const date = new Date(value);
    return isNaN(date) ? "" : format(date, 'yyyy-MM-dd');
  };

  const customLocaleText = {
    ...esES.components.MuiDataGrid.defaultProps.localeText,
    noRowsLabel: 'No se encontraron resultados...',
  };

  const [selectedRow, setSelectedRow] = useState({ id: null, correlative: null, record_status: null });

  const handleOpenInactivateDialog = (row) => {
    setSelectedRow(row);
    setOpenInactivateDialog(true);
  };

  const statusOptions = [
    { id: 1, name: 'Delivery' },
    { id: 2, name: 'Pickup' },
    { id: 3, name: 'Tránsito a bodega USA' },
    { id: 5, name: 'Tránsito a Aduana' },
    { id: 6, name: 'Trámite en Aduana' },
    { id: 7, name: 'Bodega país destino' },
    { id: 8, name: 'Trancito para entrega' },
    { id: 9, name: 'Entregada' },
  ];

  const cambiarStatus = () => {
    const newData = searchResult.map((item) => {
      const statusOption = statusOptions.find((option) => option.id === item.status);
      if (statusOption) {
        return { ...item, status: statusOption.name, statusId:item.status };
      }
      return item;
    });
    return newData;
  };

  const [sortModel, setSortModel] = useState([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const newSearch = async(body) =>{
    const maxRetries = 3;
    let retries = 0;
    //console.log('bpdy',body)
    const fetchSearchResults = async () => {
      try{
        setLastSearchParams(body)
        let res = await fetch(searchRoute(), {
          method: "POST",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          body: JSON.stringify(body),
          //cors: 'no-cors'
        })
        let data = await res.json()
        console.log('resultados busqueda: ',data)
        if(res.ok){
          //console.log('busqueda ok')        
          if (Array.isArray(data)) {
            setSearchResult(data)
          } 
          else if (typeof data === 'object' && data !== null) {
            setSearchResult([])          
          } else {
            setSearchResult([])
          }                
        }else{
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }
          mostrarErrorRequest(data.detail)
          //setErrorRequest(data.detail)
          console.log('Respuesta erronea',data) 
          //lanzamos el error 
          throw new Error(data.detail || 'Error en la solicitud');    
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        console.log('catch Busqueda',errorRequest)
        //mostrarErrorRequest(errorRequest)
        setLoadingRequest(false)
        //setErrorRequest(errorRequest)
        //console.log('busqueda catch')
        throw errorRequest; 
      }
    }
    while (retries < maxRetries) {
      try {
        return await fetchSearchResults();
      } catch (errorRequest) {
        if (errorRequest.message === 'Network Error' || errorRequest.code === 'ERR_NETWORK_CHANGED') {
          retries++;
          console.log(`Reintentando solicitud... intento ${retries}`);
          if (retries === maxRetries) {
            mostrarErrorRequest("Error de red. Por favor, verifica tu conexión e inténtalo de nuevo.");
          }
        } else {
          mostrarErrorRequest("Ha ocurrido un error. Por favor, inténtalo de nuevo.");
          break;
        }
      }
    }
  }

  const handleSearch = async (searchData) => {
    console.log('New search...', searchData);
    setLastSearch(searchData);
    if (searchData.value.trim() === "") {
      setSearchResult("");
    } else {
      newSearch(searchData);
    }
  };

  const refreshSearch = () => {
    handleSearch(lastSearch)
  }

  const openChangeStatusModalForBox = (box) => {
    setSelectedBox(box);
    setOpenChangeStatusModal(true);
  }

  const columns=[
    {
      field:"id", 
      headerName: "ID",
      cellClassName: "id-column--cell",
      headerClassName: "id-header--cell",
      hideable:false
    },
    {
      flex:0.75,
      //hide: true,
      field:"correlative", 
      headerName: "Identificador",
      cellClassName: "correlative-column--cell",
      minWidth:85,
    },
    {
      flex:0.75,
      //hide: true,
      field:"size", 
      headerName: "Tamaño",
      cellClassName: "size-column--cell",
      minWidth:70
    },
    {
      flex:1,
      //hide: true,
      field:"company", 
      headerName: "Empresa",
      cellClassName: "company-column--cell",
      minWidth:120
    },
    {
      flex:1,
      //hide: true,
      field:"delivery_user", 
      headerName: "Usuario Delivery",
      cellClassName: "delivery_user-column--cell",
      minWidth:120
    },
    {
      flex:0.8,
      //hide: true,
      field:"delivery_date", 
      headerName: "F. Delivery",
      minWidth:70,
      renderCell: ({ row: { delivery_date} }) => formatDateTime(delivery_date)
    },
    {
      flex:1,
      //hide: true,
      field:"senders_name", 
      headerName: "Nombre Envía",
      cellClassName: "senders_name-column--cell",
      minWidth:200
    },
    {
      flex:0.9,
      //hide: true,
      field:"senders_phone", 
      headerName: "Teléfono",
      cellClassName: "senders_phone-column--cell",
      minWidth:80
    },
    {
      flex:0.9,
      //hide: true,
      field:"container", 
      headerName: "Container",
      cellClassName: "container-column--cell",
      minWidth:75
    },
    {
      flex:0.9,
      //hide: true,
      field:"distribution", 
      headerName: "Reparto",
      cellClassName: "distribution-column--cell",
      minWidth:75
    },
    {
      flex:1,
      //hide: true,
      field:"pickup_user", 
      headerName: "Usuario Pickup",
      cellClassName: "pickup_user-column--cell",
      minWidth:120
    },
    {
      flex:0.8,
      //hide: true,
      field:"pickup_date", 
      headerName: "F. Pickup",
      minWidth:70,
      renderCell: ({ row: { pickup_date} }) => formatDateTime(pickup_date)
    },
    {
      flex:1,
      //hide: true,
      field:"status", 
      headerName: "Estado",
      headerAlign: "center",
      minWidth:120,
      renderCell:({row:{id, correlative, status, statusId}}) => {
        return <Box display="flex" justifyContent="space-between" alignItems="center">
          { permissionsListData.includes(PERMISSION_CHANGE_BOX_STATUS) &&
          <IconButton onClick={() => openChangeStatusModalForBox({id,correlative, status, statusId})} title="Cambiar estado" size="small" variant="contained" color="secondary">
              <ChangeCircleIcon/>
          </IconButton> }
          <Typography sx={{fontSize:'0.75rem'}}>{status}</Typography>
        </Box>
      }
    },
    permissionsListData.includes(11) ? {
      flex:1,
      minWidth:110,
      filterable:false,
      sortable:false,
      field:"payments_count", 
      headerName: "Recoger",
      headerAlign:"center",
      cellClassName: "payments_count-column--cell",
      renderCell:({ row: { correlative,payments_count,status,record_status,delivery_cost} })=>{
        return(
          record_status === 'ACTIVO' && (payments_count>0 ||delivery_cost===0) && status ==='Delivery'?
          <Box
            width="95%"
            maxWidth="110px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            <Button
              color="secondary"
              variant="contained"
              onClick={()=>{
                navigate("/PickupForm?id="+correlative)
              }}>
            <LocalShippingIcon/>
              <Box sx={{ml:"5px"}}>
                Recoger
              </Box>
            </Button>
          </Box>
        :'')
      }
    }:null,
    permissionsListData.includes(28) || permissionsListData.includes(29) ? {
      flex:1,
      minWidth:100,
      filterable:false,
      sortable:false,
      field:"actions", 
      headerName: "Ver",
      headerAlign:"center",
      cellClassName: "actions-column--cell",
      renderCell:({ row: { correlative, status } })=>{
        return(
          <Box
            width="95%"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={()=>{
                if(status==='Delivery'){
                  if(permissionsListData.includes(28)){
                  navigate("/DeliveryForm?id="+correlative)
                  }else{
                    mostrarAlertRequest('No cuenta con permisos para editar Delivery')
                  }
                }else {
                  if(permissionsListData.includes(29)){
                    navigate("/PickupForm?id="+correlative)
                  }else{
                    mostrarAlertRequest('No cuenta con permisos para editar Pickup')
                  }
                }
              }}>
            <VisibilityIcon/>
              <Box sx={{ml:"5px"}}>
                Ver
              </Box>
            </Button>
          </Box>
        )
      }
    }:null,
    permissionsListData.includes(30)?{
      flex:1,
      minWidth:100,
      field:"record_status", 
      headerName: "Anular / Reactivar",
      headerAlign:"center",
      cellClassName: "record_status-column--cell",
      renderCell:({ row })=>{
        return(
          <Box
            width="95%"
            maxWidth="170px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            {row.record_status==='ACTIVO'?<Button
              color="error"
              variant="contained"
              onClick={()=>{handleOpenInactivateDialog(row)}}>
            <DisabledByDefaultIcon/>
              <Box sx={{ml:"5px"}}>
                Anular
              </Box>
            </Button>:<Button
              color="success"
              variant="contained"
              onClick={()=>{handleOpenInactivateDialog(row)}}>
            <CheckBoxIcon/>
              <Box sx={{ml:"5px"}}>
                Activar
              </Box>
            </Button>}
          </Box>
        )
      }
    }:{
      flex:1,
      //hide: true,
      field:"record_status", 
      headerName: "Estado Registro",
      cellClassName: "record_status-column--cell",
      minWidth:100
    },
  ].filter(Boolean)
  
  if(loadingRequest)
  return(<Loading/>)

  if(errorRequest)
  return(<FatalError/>)


  return (
    <Box m="15px" marginBottom="130px" >
      <InactivateDialog
        openInactivateDialog={openInactivateDialog}
        setOpenInactivateDialog={setOpenInactivateDialog}
        id={selectedRow.id}
        correlative={selectedRow.correlative}
        record_status={selectedRow.record_status}
        pageNavigate = {'/Dashboard'}
        handleSearch={handleSearch}
        lastSearchParams = {lastSearchParams}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={!Array.isArray(searchResult)?"Aplicaciones":"Resultados de la búsqueda..."} subtitle={!Array.isArray(searchResult)?"Bienvenido":""}/>
      </Box>
      <Box m="5px">
        {permissionsListData.includes(9)?<SearchComponent onSearch={handleSearch}/>:undefined}
      </Box>

      {!Array.isArray(searchResult)?<AppsCards permissionsListData={permissionsListData}></AppsCards>:undefined}


      {Array.isArray(searchResult)?<Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                actions:true
              },
            },
          }}
          localeText={customLocaleText}
          sortModel={sortModel}
          onSortModelChange={(newModel) => setSortModel(newModel)}
          rows={cambiarStatus(searchResult)}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          /*slots={{
            toolbar: GridToolbar,
          }}*/
        />
      </Box>:undefined}
      <ChangeBoxStatusModal
        box={selectedBox}
        openDialog={openChangeStatusModal}
        setOpenDialog={setOpenChangeStatusModal}
        onChangeStatusSuccess={refreshSearch}>
      </ChangeBoxStatusModal>
    </Box>
    
  )
}

export default Dashboard;