
export const url = process.env.REACT_APP_API_URL;
// export const url = "http://localhost:8000";

//LOG IN
export const loginRoute = () =>{
  return `${url}/user/login/`
}

//USERS
export const newUserRoute = () =>{
  //return `${url}/user/create/`
  return `${url}/user`
}

export const updateUserRoute = (user_id) =>{
  //return `${url}/user/update/${user_id}`
  return `${url}/user/${user_id}`
}

export const changePasswordRoute = (user_id) =>{
  //return `${url}/user/create/`
  return `${url}/user/password/${user_id}`
}

export const usersListRoute = () =>{
  //return `${url}/user/list`
  return `${url}/users`
}

export const userRoute = (user_id) =>{
  //return `${url}/user/list/${user_id}`
  return `${url}/user/${user_id}`
}

export const addRoleToUserRoute = () =>{
  //return `${url}/user/user_roles/`
  return `${url}/role/user`
}

export const deleteRoleToUserRoute = (id_role,id_user) =>{
  return `${url}/role/user/${id_role}/${id_user}`
}

export const userRolesListRoute = (user_id) =>{
  //return `${url}/user/get_roles/${user_id}`
  return `${url}/roles/user/${user_id}`
}

export const userChangeStatusRoute = (user_id) =>{
  //return `${url}/user/status/${user_id}`
  return `${url}/user/status/${user_id}`
}

export const usersByRolRoute = (id_role) =>{
  //2 Delivery
  return `${url}/users/role/${id_role}`
}


//ROLES
export const newRoleRoute = () =>{
  //return `${url}/user/roles/`
  return `${url}/role`
}

export const allActionsRoute = () =>{
  return `${url}/actions`
}

export const actionsByRolListRoute = (rol_id) =>{
  return `${url}/role/actions/${rol_id}`
}

export const addActionToRolRoute = () => {
  return `${url}/role/action`
}

export const deleteActionRolRoute = (rol_id,action_id) => {
  //return `${url}/user/get_roles/`
  return `${url}/role/action/${rol_id}/${action_id}`
}

export const rolesListRoute = () => {
  //return `${url}/user/get_roles/`
  return `${url}/roles`
}

//STORES
export const newStoreRoute = () =>{
  return `${url}/store`
}

export const storesListRoute = () =>{
  return `${url}/stores`
}

export const storeChangeStatusRoute = (user_id) =>{
  //return `${url}/user/status/${user_id}`
  return `${url}/store/status/${user_id}`
}

//COMPANIES
export const companiesListRoute = () =>{
  return `${url}/companies`
}


//BOXES
export const newBoxRoute = () =>{
  return `${url}/box`
}

export const getMaxDiscountBoxRoute = (box_id) =>{
  return `${url}/box/discount/max/${box_id}`
}

export const modifyDiscountBoxRoute = (box_id) =>{
  return `${url}/box/discount/max/${box_id}`
}

export const boxesListRoute = () =>{
  return `${url}/boxes`
}

export const packageTypeListRoute = () =>{
  return `${url}/package/types`
}

export const boxPriceRoute = (id_box,id_country,company_id) =>{
  return `${url}/box/price/${id_box}/${id_country}/${company_id}`
}

export const boxPricesDetailRoute = (id_box) =>{
  return `${url}/box/price/detail/${id_box}`
}

export const newPriceBoxRoute = (id_box,id_country,id_company) =>{
  return `${url}/box/price/${id_box}/${id_country}/${id_company}`
}

export const modifyPriceBoxRoute = (id_box,id_country,id_company) =>{
  return `${url}/box/price/${id_box}/${id_country}/${id_company}`
}

//CLIENTS
export const clientByPhoneRoute = (phone) =>{
  return `${url}/client/phone/${phone}`
}

export const receiverClientsByIdSenderClient = (id_client) =>{
  return `${url}/client/deliveries/${id_client}`
}

//SEARCH
export const searchRoute = () =>{
  return `${url}/search`
}

//PLACES
export const reseiverCountriesRoute = () =>{
  return `${url}/countries`
}

export const statesRoute = (id_country) =>{
  return `${url}/delivery/state/${id_country}`
}

export const municipalitiesRoute = (id_state) =>{
  return `${url}/delivery/municipalities/${id_state}`
}

//DELIVERY

export const newDeliveryPickupRoute = () =>{
  return `${url}/delivery`
}

export const getDeliveryPickupByCorrelativeRoute = (correlative) =>{
  return `${url}/delivery/${correlative}`
}

export const modifyDeliveryPickupByCorrelativeRoute = (correlative) =>{
  return `${url}/delivery/${correlative}`
}

export const activateInactivateRoute = (box_id) =>{
  return `${url}/delivery/activation/${box_id}`
}

export const balancePaymentRoute = (box_id) =>{
  return `${url}/box/toggle/balancepayment/${box_id}`
}

export const boxClosureRoute = (box_id) =>{
  return `${url}/closure/box/${box_id}`
}

export const getBoxesByDateRoute = (date) =>{
  return `${url}/boxes/${date}`
}

export const getBoxAuditRoute = (box_id) =>{
  return `${url}/box/audit/${box_id}`
}

//ROUTES EEUU

export const originCountryRoutesRoute = () =>{
  return `${url}/countryroute`
}

export const newOriginCountryRoute = () =>{
  return `${url}/countryroute`
}

export const originCountryRouteDetail = (id_route) =>{
  return `${url}/countryroute/detail/${id_route}`
}

export const newOriginCountryRouteZIP = () =>{
  return `${url}/countryroute/detail`
}

export const deleteZipRouteRoute = (id_route,zip) =>{
  return `${url}/countryroute/detail/${id_route}/${zip}`
}

export const getRouteByZip = (zip) =>{
  return `${url}/countryroute/zip/${zip}`
}

export const changeBoxRouteRoute = (id_box) =>{
  return `${url}/box/route/${id_box}`
}

//ROUTES EEUU (ASIGNACION)

//asignar driver a una ruta
export const driverRouteAssignmentRoute = () =>{
  return `${url}/driver/route`
}

//obterner nombre driver asignado
export const getDriverRouteAssignmentRoute = (route_id,assignment_date) =>{
  return `${url}/driver/route/${route_id}/${assignment_date}`
}

//obtener detalle de la ruta 
export const getDriverRouteDetail = (route_id,assignment_date) =>{
  return `${url}/driver/detail/${route_id}/${assignment_date}`
}


//INGRESO DE EFECTIVO
export const getBoxPaymentsSummaryRoute = (id_box) =>{
  return `${url}/payment/summary/${id_box}`
}

export const getBoxPaymentsHistoryRoute = (id_box) =>{
  return `${url}/payment/${id_box}`
}

export const newPaymentRoute = () =>{
  return `${url}/payment`
}

export const deletePaymentRoute = (id_payment) =>{
  return `${url}/payment/${id_payment}`
}


//CONTENEDORES (CARGA)

export const newContainerRoute = () =>{
  return `${url}/container`
}

export const newContainerBoxRoute = () =>{
  return `${url}/container/box`
}

export const getContainerStatusRoute = () =>{
  return `${url}/containers/status`
}

export const getContainerByCodeRoute = (container_code) =>{
  return `${url}/container/${container_code}`
}

export const getContainerByIdRoute = (id) =>{
  return `${url}/container/id/${id}`
}

export const getContainerDetailsRoute = (id) =>{
  return `${url}/container/details/${id}`
}

export const modifyContainerStatusRoute = (id) =>{
  return `${url}/container/status/${id}`
}

export const modifyContainerRoute = (containier_id) =>{
  return `${url}/container/${containier_id}`
}

export const getContainersRoute = () =>{
  return `${url}/containers`
}

export const getContainersByDateRoute = (initial_date,final_date) =>{
  return `${url}/containers/date/${initial_date}/${final_date}`
}

export const deleteBoxContainerRoute = (id_container,id_box) =>{
  return `${url}/container/${id_container}/${id_box}`
}

// CIERRE DE RUTA DELIVERY
export const getClosureDelivery = (date,user_driver) =>{
  return `${url}/route/closure/delivery/${date}/${user_driver}`
}

// CIERRE DE RUTA PICKUP
export const getClosurePickup = (date,user_driver) =>{
  return `${url}/route/closure/pickup/${date}/${user_driver}`
}

// GASTOS DE RUTALL
export const getExpensesDateDriver = (date, user_driver) => {
  return `${url}/expenses/${user_driver}/${date}`
}

// REPORTE DE CAJAS POR DRIVER
export const getDriverBoxesReport = (type, user_driver, init_date, end_date) => {
  return `${url}/report/${type}/boxesdriver/${user_driver}/${init_date}/${end_date}`
}

// REPORTE DE CONTRATOS
export const getContractsReportRoute = (filter, container_id) => {
  return `${url}/report/contracts/${filter}/container/${container_id}`
}

// REPORTE DE CARGA
export const getContainerReportRoute = (filter, container_id) => {
  return `${url}/report/containers/${filter}/container/${container_id}`
}

// REPORTE DE TIPO DE PAGO
export const getPaymentTypeReportRoute = (init_date, end_date) => {
  return `${url}/report/paymenttype/${init_date}/${end_date}`
}

// REPORTE DE PICKUPS DE USUARIOS
export const getUserPickupsReportRoute = (init_date, end_date) => {
  return `${url}/report/userpickups/insurance/${init_date}/${end_date}`
}

// REPORTE DE INGRESO DE EFECTIVO
export const getCashIncomeReportRoute = (user,init_date,end_date) => {
  return `${url}/report/cashincome/${user}/${init_date}/${end_date}`
}

// REPORTE DE CUADRE
export const getCashBalanceReportRoute = (user_driver,receiving_user,init_date,end_date) => {
  return `${url}/report/balance/${user_driver}/${receiving_user}/${init_date}/${end_date}`
}

// REPORTE DE STICKER POR DRIVER
export const getStickersReportRoute = (user_driver, init_date, end_date) => {
  return `${url}/report/stickers/${user_driver}/${init_date}/${end_date}`
}

//GASTOS
export const newFeeRoute = () =>{
  return `${url}/expense`
}

//CIERRE DE RUTAS (CUADRE)
export const getCahsBalanceDriversRoute = () =>{
  return `${url}/balance`
}

export const getHistoryCashRoute = () =>{
  return `${url}/balance/history`
}

export const getCashBalanceByUser = (token, driver_id, date) => {
  const queryParams = new URLSearchParams({driver_id, date}).toString();
  return fetch(`${url}/balance/by-user?${queryParams}`, {
    method:'GET', headers:{Authorization:`Bearer ${token}` }
  });
}

export const createCashEntry = (token, payload) => {
  return fetch(`${url}/balance`, {
    method:'POST',
    headers:{      "Authorization":`Bearer ${token}`,
      "Content-Type": "application/json",
      "Accept":"application/json",
    },
    body:JSON.stringify(payload)
  });
}


export const newCashDriverRoute = () =>{
  return `${url}/balance`
}

//PERMISOS
export const getPermissionsRoute = (user_id) =>{
  return `${url}/user/permissions/${user_id}`
}

//CAJAS CON SALDO
export const getBalanceBoxesRoute = () =>{
  return `${url}/balance/boxes`
}

//TRACKING
export const getTrackingBoxeRoute = (box_id) =>{
  return `${url}/box/tracking/${box_id}`
}

//TRACKING
export const sendSMSRoute = (type) =>{
  return `${url}/sms/boxes/${type}`
}


//NUEVAS RUTAS

//ETIQUETA INDIVIDUAL
export const getStickerDataRoute = (box_id) =>{
  return `${url}/report/sticker/${box_id}`
}

// REPORTE DE STICKER POR DRIVER
export const getAllStickersDataRoute = (user_driver, init_date, end_date) => {
  return `${url}/report/stickers/${user_driver}/${init_date}/${end_date}`
}

//CONTRATO INDIVIDUAL
export const getContractDataRoute = (box_id) =>{
  return `${url}/report/contract/${box_id}`
}

//REPORTE DE CONTRATOS
export const getAllContractsDataRoute = (filter, container_id) => {
  return `${url}/report/contracts/${filter}/container/${container_id}`
}

//RECIBO DE CONTRATOS
export const getReceipDataRoute = (payment_id) => {
  return `${url}/report/receipt/${payment_id}`
}

// ESTADOS DE CAJAS
export const getBoxStatus = (token) => {
  return fetch(`${url}/box/status`, {
    method:'GET', headers:{Authorization:`Bearer ${token}`}
  });
}

// ESTADOS DE CAJAS
export const updateBoxStatus = (token, boxId, newBoxStatus) => {
  const payload = { boxId, status: newBoxStatus };
  return fetch(`${url}/box/change-status`, {
    method:'PUT', headers:defaultHeadersWithToken(token), body:JSON.stringify(payload)
  });
}

// CIERRE PICKUP
export const getClosureSummaryForPickup = (token, from_date, end_date) => {
  const queryParams = new URLSearchParams({from_date, end_date}).toString();
  return fetch(`${url}/closure/pickup-summary?${queryParams}`, {
    method:'GET', headers:{Authorization:`Bearer ${token}`}
  })
}

// CIERRE DELIVERY
export const getClosureSummaryForDelivery = (token, from_date, end_date) => {
  const queryParams = new URLSearchParams({from_date, end_date}).toString();
  return fetch(`${url}/closure/delivery-summary?${queryParams}`, {
    method:'GET', headers:{Authorization:`Bearer ${token}`}
  })
}

// CIERRE GASTOS
export const getClosureFeesSummary = (token, from_date, end_date) => {
  const queryParams = new URLSearchParams({from_date, end_date}).toString();
  return fetch(`${url}/closure/fees-summary?${queryParams}`, {
    method:'GET', headers:{Authorization:`Bearer ${token}`}
  })
}

// CIERRE RECEPCIÓN DE EFECTIVO
export const getClosureCashReceptionSummary = (token, from_date, end_date) => {
  const queryParams = new URLSearchParams({from_date, end_date}).toString();
  return fetch(`${url}/closure/cash-reception-summary?${queryParams}`, {
    method:'GET', headers:{Authorization:`Bearer ${token}`}
  })
}

const defaultHeadersWithToken = (token) => {
  return {
    "Content-Type": "application/json",
    "Accept":"application/json",
    "Authorization": `Bearer ${token}`
}
}


export default url;
