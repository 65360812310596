import React, { useState } from "react"
import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import useAuth from "../../../../auth/useAuth";
import { createCashEntry } from "../../../../config";

const CierreRutaCashSection = ({recordCash, totalCash, maxTotalBalance, selectedDate, selectedDriver: selectedDriverId, requireUpdateUI}) => {

    const auth = useAuth();
    const token = auth.globals.token;
    const [openAddCash, setOpenAddCash] = useState(false);
    const [disableConfirmButton, setDisableConfirmButton] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [cashAmount, setCashAmount] = useState("");

    const saveCashEntry = () => {
        const amountAsNumber = Number(cashAmount);
        if (isNaN(amountAsNumber) || amountAsNumber === 0) {
            showError("La cantidad de dinero recibido es requerida");
            return;
        }
        if (amountAsNumber < 0) {
            showError("La cantidad de dinero no puede ser negativa");
            return;
        }
        if (amountAsNumber > maxTotalBalance) {
            showError("La cantidad de dinero recibido no puede ser mayor a " + maxTotalBalance.toFixed(2));
            return;
        }
        setDisableConfirmButton(true);
        const payload = {
            "amount": cashAmount,
            "driver_cash_date": selectedDate,
            "id_user_driver": selectedDriverId
        }
        createCashEntry(token, payload)
            .then(resp => {
                setShowSuccessMessage(true);
                closeCashDialog();
            })
            .catch(err => {
                console.error('Error saving cash entry', err);
                setDisableConfirmButton(false);
            });
    }

    const showError = (message) => {
        setErrorMessage(message);
        setShowErrorMessage(true);
    }

    const closeErrorMessage = () => {
        setShowErrorMessage(false);
    }

    const closeSuccessMessage = () => {
        setShowSuccessMessage(true);
        requireUpdateUI && requireUpdateUI();
    }

    const handleCashChange = (evt) => {
        setCashAmount(evt.target.value);
    }

    const openCashDialog = () => {
        setDisableConfirmButton(false);
        setCashAmount("");
        setOpenAddCash(true);
    }

    const cancel = () => {
        closeCashDialog();
    }

    const closeCashDialog = () => {
        setOpenAddCash(false);
    }

    return <>
        <Box sx={{ marginTop: 2 }}>
            <div className="d-flex-jbetween">
                <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold'  }}> Efectivo recibido </Typography>
                <Button onClick={openCashDialog} variant="contained" color="success">Recibir efectivo</Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Usuario que recibe</TableCell>
                        <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Fecha de recepción</TableCell>
                        <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Monto recibido</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { recordCash.map((item) =>
                    <TableRow key={item.id}>
                        <TableCell align="right">{item.admin_name} {item.admin_lastname}</TableCell>
                        <TableCell align="right">{item.create_date}</TableCell>
                        <TableCell align="right">{item.amount}</TableCell>
                    </TableRow> )}
                    <TableRow>
                        <TableCell />
                        <TableCell align="right" style={{ fontWeight: 'bold' }}> Total </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}> {totalCash} </TableCell>
                    </TableRow>
                </TableBody>
                </Table>
            </TableContainer>
        </Box>
        <Dialog open={openAddCash} onClose={closeCashDialog} fullWidth={true} maxWidth="sm">
            <DialogTitle variant="h4" fontWeight="bold">Registrar dinero recibido</DialogTitle>
            <DialogContent>
                <Typography sx={{marginBottom:2}}>
                    Ingrese la cantidad de dinero recibido, esta cantidad se registrará para el día {selectedDate} y driver seleccionado.
                </Typography>
                <TextField value={cashAmount} onChange={handleCashChange} required={true} fullWidth={true} variant="filled" label="Dinero recibido" sx={{marginBottom:2}}></TextField>
                <div className="d-flex-end gap-3">
                    <Button onClick={cancel} variant="outlined" color="secondary">Cancelar</Button>
                    <Button onClick={saveCashEntry} disabled={disableConfirmButton} variant="contained" color="success">Confirmar</Button>
                </div>
            </DialogContent>
        </Dialog>

        <Snackbar
            open={showSuccessMessage}
            autoHideDuration={4000}
            onClose={closeSuccessMessage}
            anchorOrigin={{vertical:'top',horizontal:'right'}}>
            <Alert severity="success" variant="filled" onClose={closeSuccessMessage}>
                El registro de efectivo se guardó correctamente
            </Alert>
        </Snackbar>

        <Snackbar
            open={showErrorMessage}
            autoHideDuration={2500}
            onClose={closeErrorMessage}
            anchorOrigin={{vertical:'top',horizontal:'right'}}>
            <Alert severity="error" variant="filled" onClose={closeErrorMessage}>
                {errorMessage}
            </Alert>
        </Snackbar>
    </>
}

export default CierreRutaCashSection;