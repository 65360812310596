import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Box, Button, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Header from "../../../../components/Header";
import { getClosureCashReceptionSummary, getClosureFeesSummary, getClosureSummaryForDelivery, getClosureSummaryForPickup } from "../../../../config";
import useAuth from "../../../../auth/useAuth";
import { useNavigate } from "react-router-dom";

const ClosureCalendar = ({ permissionsListData }) => {

    const auth = useAuth();
    const token = auth.globals.token;
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState();
    const [openEventDetail, setOpenEventDetail] = useState(false);

    const handleDatesChange = (info) => {
        const startDate = info.start.toISOString().split("T")[0];
        const endDate = info.end.toISOString().split("T")[0];
        Promise.all([
            getClosureSummaryForPickup(token, startDate, endDate).then(resp => resp.json()),
            getClosureSummaryForDelivery(token, startDate, endDate).then(resp => resp.json()),
            getClosureFeesSummary(token, startDate, endDate).then(resp => resp.json()),
            getClosureCashReceptionSummary(token, startDate, endDate).then(resp => resp.json())
        ]).then(results => {
            console.log('RESULTS', results);
            // key is userId + date
            const hash = new Map();
            const createItem = (data) => {
                return {
                    userId: data.user_id, date: data.date,
                    userName: (data.user_name + ' ' + data.user_lastname).trim(),
                    pickupAmount: 0, deliveryAmount: 0, feeAmount: 0, cashAmount: 0
                }
            }
            const getKey = (data) => data.user_id + '$' + data.date;
            results[0].forEach(pickup => {
                const key = getKey(pickup);
                let item = hash.get(key) ;
                if (! item) {
                    item = createItem(pickup);
                    hash.set(key, item);
                }
                item.pickupAmount = pickup.amount;
            });
            results[1].forEach(delivery => {
                const key = getKey(delivery);
                let item = hash.get(key) ;
                if (! item) {
                    item = createItem(delivery);
                    hash.set(key, item);
                }
                item.deliveryAmount = delivery.amount;
            });
            results[2].forEach(fee => {
                const key = getKey(fee);
                let item = hash.get(key) ;
                if (! item) {
                    item = createItem(fee);
                    hash.set(key, item);
                }
                item.feeAmount = fee.amount;
            });
            results[3].forEach(cash => {
                const key = getKey(cash);
                let item = hash.get(key) ;
                if (! item) {
                    item = createItem(cash);
                    hash.set(key, item);
                }
                item.cashAmount = cash.amount;
            });
            setEvents(  
                Array.from(hash.values())
                    .map(item => {
                        const balance = item.pickupAmount + item.deliveryAmount - item.feeAmount - item.cashAmount;
                        const backgroundColor = balance === 0 ? "#6EEB83" : ( balance <= 0 ? "#D1495B" : "#F2F3AE" );
                        const textColor = balance < 0 ? "#EEFFEE" : "#002200";
                        return {
                            title:item.userName, start: item.date,
                            backgroundColor, textColor, borderColor: "#ededed",
                            driverId: item.userId, currentDate: item.date,
                            pickupAmount: item.pickupAmount,
                            deliveryAmount: item.deliveryAmount,
                            feeAmount: item.feeAmount,
                            cashAmount: item.cashAmount, balance
                        }
                    })
            );
        })
    };

    const handleEventClick = (event) => {
        setSelectedEvent(event);
        setOpenEventDetail(true);
    }

    const handleGoToClosureDetailClick = () => {
        closeEventDetailModal();
        const params = new URLSearchParams({
            date: selectedEvent.event.extendedProps['currentDate'],
            driver: selectedEvent.event.extendedProps['driverId']
        });
        navigate(`/CierreRuta?${params.toString()}`);
    }

    const closeEventDetailModal = () => {
        setOpenEventDetail(false);
    }

    return <>
        <Box m="20px" marginBottom="64px">
            <Header title="Calendario de cierre" subtitle="Consulte los resultados parciales del cierre por mes"/>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                datesSet={handleDatesChange}
                events={events}
                eventClick={handleEventClick}
                eventContent={RenderEventContent}/>
        </Box>
        <Dialog open={openEventDetail} onClose={closeEventDetailModal} fullWidth={true} maxWidth="md">
            <DialogTitle style={{fontWeight:'900',fontSize:'24px'}}>
                {selectedEvent?.event.extendedProps['currentDate']} - {selectedEvent?.event.title}
            </DialogTitle>
            <DialogContent>
                <Table className="mb-3">
                    <TableBody>
                        <TableRow>
                            <TableCell align="right"><b>Efectivo en pickup</b></TableCell>
                            <TableCell>
                                <div style={{display:'flex',justifyContent:'space-between',width:'80px'}}>
                                    <span>(+)</span>
                                    <span style={{display:'block',textAlign:'right'}}>
                                        {(selectedEvent?.event.extendedProps['pickupAmount'] || 0).toFixed(2)}
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right"><b>Efectivo en delivery</b></TableCell>
                            <TableCell>
                                <div style={{display:'flex',justifyContent:'space-between',width:'80px'}}>
                                    <span>(+)</span>
                                    <span style={{display:'block',textAlign:'right'}}>
                                        {(selectedEvent?.event.extendedProps['deliveryAmount'] || 0).toFixed(2)}
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right"><b>Gastos</b></TableCell>
                            <TableCell>
                                <div style={{display:'flex',justifyContent:'space-between',width:'80px'}}>
                                    <span>(-)</span>
                                    <span style={{display:'block',textAlign:'right'}}>
                                        {(selectedEvent?.event.extendedProps['feeAmount'] || 0).toFixed(2)}
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right"><b>Efectivo recibido</b></TableCell>
                            <TableCell>
                                <div style={{display:'flex',justifyContent:'space-between',width:'80px'}}>
                                    <span>(-)</span>
                                    <span style={{display:'block',textAlign:'right'}}>
                                        {(selectedEvent?.event.extendedProps['cashAmount'] || 0).toFixed(2)}
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right"><b>Balance</b></TableCell>
                            <TableCell>
                                <div style={{display:'flex',justifyContent:'space-between',width:'80px'}}>
                                    <span>(=)</span>
                                    <span style={{display:'block',textAlign:'right'}}>
                                        {(selectedEvent?.event.extendedProps['balance'] || 0).toFixed(2)}
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div className="d-flex-end gap-3">
                    <Button onClick={closeEventDetailModal} variant="outlined" color="secondary">Cancelar</Button>
                    <Button onClick={handleGoToClosureDetailClick} variant="contained" color="info">Ver detalle</Button>
                </div>
            </DialogContent>
        </Dialog>
    </>
};

const RenderEventContent = (eventInfo) => {

    return <>
      <div className="d-flex-jbetween c-pointer p-2">
        <span>{eventInfo.event.title}</span>
        <b>{(eventInfo.event.extendedProps['balance'] || 0).toFixed(2)}</b>
      </div>
    </>
    
  }
  

export default ClosureCalendar;
