
import React, { useEffect, useState } from "react"
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Snackbar, Typography } from "@mui/material"
import { getBoxStatus, updateBoxStatus } from "../config";
import useAuth from "../auth/useAuth";
import { useCustomSnackbar } from "./UseCustomSnackbar";

const ChangeBoxStatusModal = ({box, openDialog, setOpenDialog, onChangeStatusSuccess, permissionListData = []}) => {

    const auth = useAuth();
    const token = auth.globals.token;
    const [availableStatus, setAvailableStatus] = useState([]);
    const [newStatus, setNewStatus] = useState({id:-1});
    const { showSuccessSnackbar, showErrorSnackbar, CustomSnackbarComponent } = useCustomSnackbar();

    useEffect(() => {
        getBoxStatus(token)
            .then(resp => resp.json())
            .then(data => {
                console.log("Received data:", data);
                setAvailableStatus(data);
                setCurrentStatus(box, data);
            })
            .catch(err => console.error("Error getting box-status:", err));
    }, []);

    useEffect(() => {
        setCurrentStatus(box, availableStatus);
    }, [box, openDialog])

    const setCurrentStatus = (currentBox, currentAvailableStatus) => {
        if (currentBox && currentAvailableStatus) {
            const currentStatus = currentAvailableStatus.find(status => status.id == box.statusId);
            if (currentStatus) {
                setNewStatus(currentStatus);
            }
        }
    }

    const submitChangeStatus = () => {
        updateBoxStatus(token, box.id, newStatus.id)
            .then(resp => resp.json())
            .then(resp => {
                console.log('Status changed:', resp);
                showSuccessSnackbar("Se actualizó correctamente el estado de la caja " + box.correlative)
                onChangeStatusSuccess && onChangeStatusSuccess();
                handleCloseDialog();
            })
            .catch(err => {
                console.error('Error on status change:', err);
                showErrorSnackbar("Error al cambiar e       l estado de la caja " + box.correlative);
            });
    }

    const handleNewStatusChange = (ns) => {
        console.log('New status detected', ns);
        setNewStatus(ns.target.value);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    return <>
        { <>
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth={true} maxWidth="sm">
                <DialogTitle sx={{fontWeight:900, fontSize:18}}>
                    Cambiar estado de la caja {box?.correlative}
                </DialogTitle>
                <DialogContent>
                    <Box component="form" display="flex" sx={{gap:2, flexDirection:'column'}}>
                        <Typography>
                            Seleccione el estado al que desea cambiar la caja <b>{box?.correlative}</b>,
                            luego presione el botón "Cambiar estado" para realizar el cambio
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel id="new-status-input-label">Nuevo estado</InputLabel>
                            <Select 
                                labelId="new-status-input-label"
                                id="new-status-input"
                                label="Nuevo estado"
                                value={newStatus}
                                onChange={handleNewStatusChange}>
                            { availableStatus.map(status => 
                                <MenuItem key={status.id} value={status}>
                                    {status.name}
                                </MenuItem>
                            )}
                            </Select>
                        </FormControl>
                        <Box justifyContent="end" display="flex">
                            <Button onClick={submitChangeStatus} disabled={newStatus.id == -1 || newStatus.id == box?.statusId} variant="contained" color="secondary">Cambiar estado</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            {CustomSnackbarComponent}
        </> }
    </>
}

export default ChangeBoxStatusModal;
